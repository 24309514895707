export var Cfg = {
    BackendUrl: 'https://portal-tsc.com/bkd',
    BackendUrladj: 'https://portal-tsc.com',
    //BackendUrl: 'https://demo.mksoftwaredev.com/bkd',
    //BackendUrladj: 'https://demo.mksoftwaredev.com',
    // BackendUrl: 'http://localhost:3030/bkd',    
    // BackendUrladj: 'http://localhost:3030',
    formatoFecha: 'MM/dd/yyyy',
    //formatoFecha: 'dd/MM/yyyy',
    devempresa:'MK Software',
    navnombre:'TotSol'    

}


